@import '@styles/styles.scss';

.itemField {
    .fieldValueWrapper {
        .fieldValue {
            justify-content: center;
            align-items: center;
            margin: 0;

            .fieldValueTxt {
                margin-right: 0;
            }
        }
    }

    &.sellerName {
        .itemFieldLeft {
            align-items: center;
        }
    }

    &.realAmount {
        .fieldValue {
            align-items: center;
        }
    }

    .itemFieldLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .fieldLabelWrapper {
            display: block;

            margin: 0;

            @include desktop {
                min-width: auto !important;
            }

            .fieldLabel {
                display: flex;
                justify-content: center;
                padding-right: 0;
            }
        }
    }

    .fieldValueWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
    }
}
