@import '@styles/styles.scss';

.stepper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 155px;

    @include mobile {
        height: auto;
    }

    .brandLogoInfos {
        @include mobile {
            padding: 20px 0;
        }
    }

    .stepperLeft,
    .stepperRight {
        width: 50%;
        height: 100%;
        // @include desktop {
        //     margin: 0 10px;
        // }
        .stepIndexWrapper,
        .stepNameWrapper {
            height: 100%;

            @include mobile {
                height: auto;
            }
        }

        // .stepNameWrapper {
        //     margin-left: 20px;

        //     @include tablet {
        //         margin-left: 10px;
        //     }
        // }
    }

    .stepperRight {
        display: flex;
        text-align: center;
        line-height: 1.5;
        margin-left: 20px;

        @include tablet {
            margin-left: 10px;
            margin-right: 0;
        }

        @include mobile {
            margin-top: 10px;
        }
    }

    .stepIndexTitle,
    .stepIndex {
        font-size: $FONTSIZE_32;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;

        @include mobileLarge {
            font-size: $FONTSIZE_24;
        }
    }

    .stepIndexTitle {
        text-transform: uppercase;
    }

    .stepName {
        font-size: $FONTSIZE_30;
        font-weight: 500;

        @include mobileLarge {
            font-size: $FONTSIZE_24;
        }
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        // padding: 16px 18px;

        .stepperLeft,
        .stepperRight {
            width: 100%;
        }
        .stepIndexTitle,
        .stepIndex,
        .stepName {
            font-size: $FONTSIZE_24;
        }
    }
}
