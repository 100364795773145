@import '@styles/styles.scss';

.timer {
    p {
        color: $COLOR_BLACK;
        font-size: $FONTSIZE_22;
        font-weight: $FONTWEIGHT_MEDIUM;
    }

    .timerCountDown {
        font-weight: $FONTWEIGHT_BOLD;
    }
}
