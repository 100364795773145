@import '@styles/styles.scss';

.traderInfosWrapper {
    .fieldValue {
        font-size: $FONTSIZE_22;
    }

    .fieldValueWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
    }

    .fieldExtra {
        margin-top: 55px;
    }
}
