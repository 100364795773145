@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    .qrCodeImg {
        border-radius: 20px;
        padding: 15px;
        background-color: $COLOR_WHITE;
        border: 1px solid $primaryColor;
    }
}
