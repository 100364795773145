@import '@styles/styles.scss';

.wrapper {
    &.sellPayingTwoStepSimple {
        .infosHeader,
        .infosGroupWrapper {
            width: 100%;
        }
    }
}
