@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        display: none;
    }

    .btn {
        &.btnCopy {
            background-color: $COLOR_WHITE;
            border: 1px solid $COLOR_BLACK;
            border-radius: 10px;
            margin-left: 8px;

            &.MuiButton-text {
                color: $COLOR_BLACK;
                height: 26px;
                min-height: auto;
                width: 30px;
                min-width: 30px;
            }
        }
    }
}
