@import '@styles/styles.scss';

.logoBrand {
    width: 190px;

    @include tablet {
        width: 150px;
    }

    @include mobileLarge {
        width: 225px;
    }
}
