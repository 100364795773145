@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    border-radius: 40px;
    width: 100%;

    &.brandLogoInfos {
        height: 100%;
        margin: 0;
        padding: 0;
        display: none;
    }

    .infosTitle {
        font-size: $FONTSIZE_36;
        padding: 0 15px 25px 15px;
        text-align: center;
    }

    .infosWrapper {
        .rightInfos {
            &.fieldLabelWrapper {
                min-width: 20px;
            }
        }

        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }

    @include mobile {
        padding: 20px 10px;
        border-radius: 20px;

        &.stepIndexWrapper,
        &.stepNameWrapper {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .infosTitle {
            padding: 10px 15px;
            font-size: $FONTSIZE_18;
        }
    }
}
