@import '@styles/styles.scss';

.actionsDialogContent {
    .headline {
        position: relative;
        .iconTitle {
            width: 80px;
            height: 80px;
        }

        .title {
            margin-bottom: 15px;
        }
    }

    .contentWrapper {
        margin-bottom: 10px;
    }

    .dialogActionGroup.MuiDialogActions-root {
        padding-left: 24px;
        padding-right: 24px;
        .btn {
            height: 50px;
            width: 170px;
            margin-bottom: 10px;
        }

        @include mobile {
            .btn:only-child {
                margin-left: auto;
            }
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
