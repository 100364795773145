@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 20px;
    background-color: $infosBkColor;
    padding: 50px;
    color: $COLOR_BLACK;
    border-radius: 40px;
    width: 50%;
    @include tablet {
        width: 100%;
    }

    @include mobile {
        border-radius: 20px;
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-bottom: 20px;
            font-size: $FONTSIZE_40;
            text-align: center;
        }

        .iconHead {
            width: 140px;
            margin-bottom: 50px;

            @include tablet {
                width: 120px;
                height: 120px;
                margin-bottom: 40px;
            }
            @include mobile {
                width: 64px;
                height: 64px;
                margin-bottom: 20px;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        text-align: center;
    }

    @include mobile {
        padding: 20px;

        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }
        }
    }
}
