@import '@styles/styles.scss';
@import '@components/InfosForm/AmountInfos/AmountInfos.scss';

.amountInfosWrapper {
    flex: 1;

    .infosWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mainInfosItemsWrapper {
        width: 100%;
    }

    .leftInfos {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .itemFieldList {
            display: flex;
            justify-content: space-between;
        }
    }

    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .itemField.unitCount {
        display: none;
    }

    @include tablet {
        .leftInfos {
            .itemFieldList {
                flex-direction: column;
            }
        }
    }

    @include mobile {
        .leftInfos {
            .itemFieldList {
                display: block;
            }
        }

        .rightInfos {
            .realAmount {
                .itemFieldLeft {
                    align-items: flex-start;
                }
            }
        }
    }
}
