@import '@styles/styles.scss';

/*=============================================
=                 Page Templates              =
=============================================*/

.mainInfosWrapper {
    min-width: 800px;
    background-color: $COLOR_WHITE;
    display: flex;

    @include tablet {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: auto;
    }
}
/*=====  End of Page Templates  ======*/

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;

    @include tablet {
        padding: 20px 0 20px;
    }

    @include mobile {
        margin-top: 0;
        padding: 10px 0 20px;
        &:after {
            display: none;
        }
    }
}

.btn {
    &.MuiButton-root,
    // &.MuiButton-text,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        border-radius: 40px;

        &:hover {
            box-shadow: none;
            background-color: $COLOR_SILVER;
        }
    }

    &.MuiButton-contained {
        // svg .fillArea {
        //     fill: $primaryTextContrast;
        //     stroke: $primaryTextContrast;
        // }

        &.Mui-disabled {
            background-color: $primaryColor;
            color: rgba($primaryTextContrast, 0.5);
            opacity: 0.5;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        border: 1px solid $primaryColor;
        background-color: rgba(176, 176, 176, 0.04);
        color: $primaryColor;

        &:hover {
            box-shadow: none;
            background-color: $COLOR_SILVER;
            border: 1px solid $primaryColor;
        }

        .MuiButton-label {
            color: $primaryColor;
        }

        // svg .fillArea {
        //     fill: $primaryColor;
        // }
    }

    .content .iconAddition {
        display: none;
    }
}

.dialogActionGroup {
    &.MuiDialogActions-root {
        display: flex;
        justify-content: flex-end;
    }
}

/*=====  End of Buttons  ======*/

.remarkMsg {
    text-align: center;
}

.itemField.unitCount,
.itemField.realAmount {
    .unit,
    .numeric {
        color: $primaryColor;
        background: -webkit-linear-gradient(90deg, #c60000 0%, #f500ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1;
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $infosBkColor;
        border: 1px solid $COLOR_MIDGREY;
    }

    .MuiAlert-icon {
        display: none;
    }
}

.wrapper {
    .infosGroupWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;

        @include tablet {
            width: 100%;
            margin: auto;
        }

        &.infosGroupWrapperBeta {
            margin-left: 20px;

            @include tablet {
                margin-left: 0;
            }
        }
    }

    .infosGroupWrapperAlpha,
    .infosGroupWrapperBeta,
    .infosHeader,
    .orderInfos + .orderInfos {
        margin-top: 20px;

        @include tablet {
            margin-top: 10px;
        }
    }

    .infosHeader,
    .infosGroupWrapper {
        width: 50%;
        @include tablet {
            width: 100%;
        }
    }

    .totalAmountInfos,
    .recipientInfos {
        height: 100%;
    }

    .cancelTimerInfosWrapper {
        .timer {
            p {
                color: $primaryColor;
            }
        }
    }
}
